<template>
  <div class="layout-container">
    <router-view />
    <div class="tab-container">
      <van-tabbar v-model="state.active" @change="onChange">
        <van-tabbar-item>
          <span>首页</span>
          <template #icon="props">
            <v-iconFontSymbol
              :icon="props.active ? 'iconhome' : 'icong_home'"
              :style="{ 'font-size': '0.2rem' }"
            />
          </template>
        </van-tabbar-item>
        <van-tabbar-item>
          <span>云课堂</span>
          <template #icon="props">
            <v-iconFontSymbol
              :icon="props.active ? 'iconhome_video' : 'iconhome_g_video'"
              :style="{ 'font-size': '0.2rem' }"
            />
          </template>
        </van-tabbar-item>
        <van-tabbar-item>
          <span>我的</span>
          <template #icon="props">
            <v-iconFontSymbol
              :icon="props.active ? 'iconhome_my' : 'iconhome_g_my'"
              :style="{ 'font-size': '0.2rem' }"
            />
          </template>
        </van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import { reactive, onMounted, onUpdated } from "vue";
import { useRouter } from "vue-router";
import IconFontSymbol from "@/components/IconFontSymbol.vue";
const routerArr = ["home", "class", "personal"];
export default {
  name: "Home",
  components: {
    "v-iconFontSymbol": IconFontSymbol
  },
  setup() {
    const state = reactive({
      active: 0
    });
    const router = useRouter();

    const onChange = value => {
      router.replace({
        name: routerArr[value]
      });
    };

    onUpdated(() => {
      state.active = routerArr.indexOf(
        window.location.href.split("/")[
          window.location.href.split("/").length - 1
        ]
      );
    });

    onMounted(() => {
      state.active = routerArr.indexOf(
        window.location.href.split("/")[
          window.location.href.split("/").length - 1
        ]
      );
    });

    return { state, onChange };
  }
};
</script>
